var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mian-mian clearfix"},[_c('img',{staticClass:"img-headlm",attrs:{"src":require("../assets/images/img-main.png")}}),_c('div',{staticClass:"right-main"},[_c('img',{staticClass:"img-headrm",attrs:{"src":require("../assets/images/img-node.png"),"alt":"Title"}}),_c('ul',{staticClass:"list-link"},[_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(1),_vm._m(2)]),_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(3),_vm._m(4)]),_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(5),_vm._m(6)]),_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(7),_vm._m(8)]),_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(9),_vm._m(10)]),_c('li',{on:{"click":_vm.onChangePage}},[_vm._m(11),_vm._m(12)])]),_vm._m(13)])])]),_c('footer',[_vm._v("Copyright © 澳門金沙 Reserved")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"clearfix"},[_c('div',{staticClass:"header-main"},[_c('a',{staticClass:"left-header"},[_c('img',{attrs:{"src":require("../assets/images/logo.png"),"alt":"Logo"}})]),_c('img',{staticClass:"right-header",attrs:{"src":require("../assets/images/head-right.png"),"alt":"Title"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-cn.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国大陆")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-hk.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国香港")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-macau.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国澳门")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-taiwan.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国台湾")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-asia.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("亚洲路线")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{attrs:{"src":require("../assets/images/map-inter.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("国际路线")]),_c('img',{staticClass:"marr30px",attrs:{"src":require("../assets/images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-content"},[_c('a',{attrs:{"href":"https://77mwp8m6rt.2hvrlhhe.com/2b24bc244f57d5fjkfle-kelib42e4050d0f0a04020c0601040a090a0b060b0e080f0c81eb02084fc64dfd7f5","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/btn-service.png")}})]),_c('a',{attrs:{"href":"https://99088.app/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/btn-download.png")}})])])
}]

export { render, staticRenderFns }